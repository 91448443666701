import { Component, OnInit } from "@angular/core";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { MedicineService } from "src/app/services/medicine.service";
import { AlertifyService } from "src/app/common/alertify.service";
import { ActivatedRoute } from "@angular/router";
import { MedicineTypeService } from 'src/app/services/medicine-type.service';
import { GroupService } from 'src/app/services/group.service';
import { CompanyService } from 'src/app/services/company.service';
import { MeasurementUnitService } from 'src/app/services/measurement-unit.service';

@Component({
  selector: "app-medicine-form",
  templateUrl: "./medicine-form.component.html",
  styleUrls: ["./medicine-form.component.scss"]
})
export class MedicineFormComponent extends BasicFormComponent<any>
  implements OnInit {
  constructor(
    public medicineService: MedicineService,
    public alertify: AlertifyService,
    private activatedRoute: ActivatedRoute,
    private medicineTypeService: MedicineTypeService,
    private groupService: GroupService,
    private companyService: CompanyService,
    private measurementUnitService: MeasurementUnitService

  ) {
    super(medicineService, alertify);
  }

  medicineForms = [
    { "form": "Powder" },
    { "form": "Liquid" },
    { "form": "Bolus" },
    { "form": "Oral Solution" },
    { "form": "Oral powder" },
    { "form": "Suspension" },
    { "form": "Injection" },
    { "form": "Tablet" },
    { "form": "Cream" },
    { "form": "Ointment" },
    { "form": "Pour On" },
    { "form": "Drop" },
    { "form": "Spray" }
  ]
  medicineTypes;
  groups;
  companies;
  units;
  btnName = 'Update';
  ngOnInit() {
    let id = this.activatedRoute.snapshot.params.id
    id ? this.get(id) : this.btnName = 'Add New'
    this.medicineTypeService.getAll().subscribe(res => this.medicineTypes = res, err => this.alertify.error(err))
    this.groupService.getAll().subscribe(res => this.groups = res, err => this.alertify.error(err))
    this.companyService.getAll().subscribe(res => this.companies = res, err => this.alertify.error(err))
    this.measurementUnitService.getAll().subscribe(res => {
      this.units = res
      console.log(res)
    })
  }
}
