import { Component, OnInit } from '@angular/core';
import { FarmService } from 'src/app/services/farm.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-farm-detail',
  templateUrl: './farm-detail.component.html',
  styleUrls: ['./farm-detail.component.scss']
})
export class FarmDetailComponent implements OnInit {

  constructor(private farmService:FarmService,private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this.getFarmDetails();
  }
  displayedColumns = ['BatchPhase', 'Quantity','BatchCount', 'ChickCount','ParentCount'];

  details:FarmDetail[]
  birds;
  chicks;
  batches;
  parents;

  getFarmDetails(){
    this.farmService.getFarmDetail(this.activatedRoute.snapshot.params.id).subscribe(res=>{
      this.details=res
      this.batches= this.details.map(t => t.BatchCount).reduce((acc, value) => acc + value, 0);
      this.birds= this.details.map(t => t.Quantity).reduce((acc, value) => acc + value, 0);
      this.chicks= this.details.map(t => t.ChickCount).reduce((acc, value) => acc + value, 0);
      this.parents= this.details.map(t => t.ParentCount).reduce((acc, value) => acc + value, 0);
      console.log(res)
    })
  }
 
}


export interface FarmDetail {
  BatchPhase: string
  Quantity: number
  BatchCount: number
  ChickCount: number
  ParentCount: number
  EggCount: number
}
