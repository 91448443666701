import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { MedicineTypeService } from 'src/app/services/medicine-type.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-medicine-type-form',
  templateUrl: './medicine-type-form.component.html',
  styleUrls: ['./medicine-type-form.component.scss']
})
export class MedicineTypeFormComponent extends BasicFormComponent<any> implements OnInit {

  constructor(public medicineTypeService:MedicineTypeService,
    public alertify:AlertifyService,private activatedRoute:ActivatedRoute) {
    super(medicineTypeService,alertify)
   }

  btnName='Update';
  ngOnInit() {
    let id=this.activatedRoute.snapshot.params.id
    id?this.get(id):this.btnName='Add New'
   }

  

}
