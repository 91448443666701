import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user';

@Injectable()
export class AuthService {

  constructor(
    private router: Router, private http: HttpClient) { }

  // login(user: User): Observable<any> {
  //   if (user.Phone !== '' && user.Password !== '' ) {
  //     //HardCoded Admin User Type
  //     user.UserType = 5;
  //    return  this.http.post(environment.baseApiUrl+'UserInfoes/LoginOld',user);
  //   }

  login(user: User): Observable<any> {
    if (user.Phone !== '' && user.Password !== '') {
      return this.http.post(environment.baseApiUrl + 'UserInfoes/AdminLogin', user);
    }
  }


  logout() {
    localStorage.removeItem('UserId')
    localStorage.removeItem('LoggedInStatus')
    localStorage.clear();
    this.router.navigate(['/login'])
  }

  loggedIn() {
    if (localStorage.getItem('LoggedInStatus') == 'true') {
      return true;
    }
    return false;
  }
  getToken() {
    return localStorage.getItem('LoggedInStatus');
  }
}
