import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { StateService } from "src/app/services/state.service";
import { CityService } from "src/app/services/city.service";
import { CountryService } from "src/app/services/country.service";
import { UserType } from "src/app/models/user-type";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { UserInfoRequestModel } from 'src/app/models/user-info-request-model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { FormControl } from "@angular/forms";
import { DeviceService, DeviceInfo } from 'src/app/services/device.service';

@Component({
  selector: "app-device-list",
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent implements OnInit {

  headElements = [
    "Device Name",
    "Temperature",
    "Humidity",
    "Ammonia",
    "Date"
  ];

  constructor(public deviceService: DeviceService) { }

  deviceInfors : DeviceInfo[];

  ngOnInit() {

    this.deviceService.getDeviceInfos().subscribe(summary => {
      this.deviceInfors = summary;
    });

  }

 
}
