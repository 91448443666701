// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://pmapi.manufarms.com/api/',
  baseUrl: 'https://pmapi.manufarms.com',
  // baseApiUrl: 'http://localhost:59240/api/',
  // baseUrl: 'http://localhost:59240/',
  deviceBaseUrl: 'http://172.105.36.66:8009/'
};


