import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
baseUrl=environment.baseApiUrl
farm:string
user:string
country:string
company:string
institute:string
medicineType:string
group:string
medicine:string
measurementUnit:string
birdType:string
activity:string
todaysActivity:string
activeFarm:string
farmsActivity:string
city:string
  constructor() { 
     this.farm=this.baseUrl+'Farms'
     this.country=this.baseUrl+'Countries'
     this.user=this.baseUrl+'UserInfoes'
     this.company=this.baseUrl+'Companies'
     this.institute=this.baseUrl+'Institutes'
     this.medicineType=this.baseUrl+'MedicineTypes'
     this.group=this.baseUrl+'GroupLists'
     this.medicine=this.baseUrl+'MedicineLists'
     this.measurementUnit=this.baseUrl+'MeasurementUnits'
     this.birdType=this.baseUrl+'BirdTypes'
     this.activity=this.baseUrl+'Activities'
     this.todaysActivity=this.baseUrl+'TodaysActivities'
     this.activeFarm=this.baseUrl+'ActiveFarms'
      this.farmsActivity=this.baseUrl+'FarmsActivity'
      this.city=this.baseUrl+'Cities'

  }
}
