import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../common/base.service';
import { UrlService } from '../common/url.service';
import { Resolve } from '@angular/router';
import { FarmDetail } from '../components/farms/farm-detail/farm-detail.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
//implements Resolve<any>
export class FarmService extends BaseService<any> implements Resolve<any> {
  

  constructor(public http:HttpClient,url:UrlService) {
    super(http,url.farm)
   }


   getFarmDetail(id:number):Observable<FarmDetail[]>{
    return this.http.get<FarmDetail[]>(environment.baseApiUrl+'Farms/FarmDetails?Id='+id)
   }
}
