import { Component, OnInit } from '@angular/core';
import { ActivityTracker } from 'src/app/models/activity-tracker';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { ActivityService } from 'src/app/services/activity.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivityRequestModel } from 'src/app/models/activity-request-model';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent extends BasicFormComponent<any> implements OnInit {

  requestModel: ActivityRequestModel;
  constructor(public http: HttpClient, private datePipe: DatePipe, public activityService: ActivityService, public alertify: AlertifyService) {
    super(activityService, alertify)
    this.requestModel = new ActivityRequestModel();
    this.requestModel.OrderBy = "Time";
  }


  ngOnInit(): void {
    this.search();
  }
  displayedColumns = ['FarmId', 'Farm', 'Phone', 'Area', 'BirdType', 'Batch', 'NumberOfBirds', 'UserName', 'UserType', 'Activity', 'Time'];

  selectFromDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.requestModel.From = new Date(this.datePipe.transform(event.value, "yyyy-MM-dd"));
  }
  selectToDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date: Date = event.value;
    // date.setDate(date.getDate() + 1)
    this.requestModel.To = date
  }

  searching() {
    if (this.requestModel.To) {
      this.requestModel.To.setDate(this.requestModel.To.getDate() + 1)  //adding one day to javascript date
      this.requestModel.To = new Date(this.datePipe.transform(this.requestModel.To, "yyyy-MM-dd"))
    }
    this.baseService.getAllWithPagination(this.requestModel).subscribe(res => {
      this.collection.data = res.data;
      this.collection.count = res.count;
      this.config = {
        itemsPerPage: this.requestModel.PerPageCount,
        currentPage: this.requestModel.CurrentPage,
        totalItems: this.collection.count
      };
    });
  }


}
