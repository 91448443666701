import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivityTracker } from 'src/app/models/activity-tracker';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { TodaysActivityService, ActiveFarm, ActiveUser } from 'src/app/services/todays-activity.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivityRequestModel } from 'src/app/models/activity-request-model';
import { ActivatedRoute } from '@angular/router';

export class Activity {
  FarmId?:number
  UserId?:number
  ActivityAt?:Date
  Operation:string
  FarmName:string
  UserName:string
 }
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent extends BasicFormComponent<any> implements OnInit {
  requestModel;
  constructor(public todaysActivityService:TodaysActivityService,public alertify:AlertifyService,private activatedRoute:ActivatedRoute ){
  super(todaysActivityService,alertify)
  this.requestModel=new ActivityRequestModel();
  this.requestModel.OrderBy="Time";
}

ngOnInit(): void {
 this.requestModel.FarmId= this.activatedRoute.snapshot.params.farmId
  this.search();

  }
  activeFarms:ActiveFarm[]
  activeUsers:ActiveUser[]
  displayedColumns = ['BatchName','NumberOfBirds','UserName','UserType','Activity','Time'];
  searching(){
  this.search();
  }

  getActiveFarms(){
    this.todaysActivityService.getActiveFarms().subscribe(res=>{
         this.activeFarms=res
    })
  }

  getActiveUsers(){
    this.todaysActivityService.getActiveUsers().subscribe(res=>{
         this.activeUsers=res
    })
  }

  onSelectFarm(){
    this.search();
  }
  onSelectUser(){
    this.search();
  }


}
