import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
 imgHidden=false;
 isExpanded = true;
 

  constructor(private authService:AuthService) { }
  showDeletedItem=true;
  ngOnInit() {
    
  }
 
  toggle() {
    this.isExpanded = !this.isExpanded;
    this.imgHidden=!this.imgHidden;
  }

  logout(){
      this.authService.logout();
  }

  refresh(){
    localStorage.clear();
    location.reload();
  }


  
}
