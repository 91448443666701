import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit {

  constructor(private http:HttpClient) {
    this.model=new SqlCommandModel();
   
   }
 model:SqlCommandModel
 models:any[]
 result;
 tables:TableName[];
  ngOnInit() {
    this.getAllTables();
  }


  executeSelectStatement(){
    //execute select statement
      this.http.post<any[]>(environment.baseApiUrl+'Dashboard/query',this.model).subscribe(res=>{
        this.models=res
      })
  }
  executeQueryResult(){
 //   this.model.Entity="";
    this.http.post<any[]>(environment.baseApiUrl+'Dashboard/sql-query',this.model).subscribe(res=>{
      this.result=res;
    })
  }

  getAllTables(){
    this.http.get<TableName[]>(environment.baseApiUrl+'Activities/Tables').subscribe(res=>{
     this.tables=res;
    
    })
  }

  execute(){
    this.model.Entity==null?this.executeQueryResult():this.executeSelectStatement()
  }


}

export class SqlCommandModel{
  Query:string
  Entity:string
}
export class TableName{
  TABLE_NAME:string

}