import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../common/base.service';
import { UrlService } from '../common/url.service';
import { Resolve } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<any> implements Resolve<any> {
 
  constructor(public http:HttpClient,private urlService:UrlService) {
    super(http,urlService.user)
   }
  uploadNid(id,imageCode):Observable<any>{
  return  this.http.post(environment.baseApiUrl+'UserInfoes/PostNIDCopy',{userId:id,imageCode:imageCode})
  }

  uploadProfilePic(id,imageCode):Observable<any>{
    return  this.http.post(environment.baseApiUrl+'UserInfoes/PostProfilePic',{userId:id,imageCode:imageCode})
    }

    getOwners():Observable<any>{
     return this.http.get(environment.baseApiUrl+'UserInfoes?userType=2');
    }
}
