import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ModelBase } from "./model-base";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import * as CryptoJS from "crypto-js";
import { SecurityKeys } from './SecurityKeys';
import { environment } from 'src/environments/environment';
import { BaseRequestModel } from '../models/base-request-model';


export class BaseService<T> {
  url: string;
  list$: any[];

  constructor(public http: HttpClient, public commandUrl: string) {
    this.url = commandUrl;
  }

  resolve(
    route: import("@angular/router").ActivatedRouteSnapshot,
    state: import("@angular/router").RouterStateSnapshot
  ) {
    let showDeletedItem = route.queryParamMap.get("showDeletedItem");
    let timeZone = route.queryParamMap.get("timeZone");

    if (showDeletedItem && timeZone) {
      return this.http
        .get<T>(this.url + "?showDeletedItem=" + showDeletedItem + "&timeZone=" + timeZone)
        .pipe(shareReplay(1));
    }
    if (showDeletedItem) {
      console.log(showDeletedItem);
      return this.http
        .get<T>(this.url + "?showDeletedItem=" + showDeletedItem)
        .pipe(shareReplay(1));
    }
    return this.http.get<T>(this.url).pipe(shareReplay(1));
  }

  get(id: number): Observable<T> {
    return this.http.get<T>(this.url + "?id=" + id);
  }

  getAll(showDeletedItem?: boolean): Observable<T> {
    if (showDeletedItem) {
      return this.http.get<T>(this.url + "?showDeletedItem=" + showDeletedItem);
    }
    return this.http.get<T>(this.url);
  }
  getAllWithPagination(model: BaseRequestModel): Observable<any> {
    model.CurrentPage = 1;
    return this.http.post<any>(this.url + '/PagedList', model);
  }
  getAllWithPaginationWhenPageChanged(model: BaseRequestModel): Observable<any> {
    return this.http.post<any>(this.url + '/PagedList', model);
  }
  save(obj: ModelBase): Observable<any> {
    obj.CreatedBy = localStorage.getItem("user");
    return this.http.post(this.url, obj);
  }
  edit(id: number, obj: ModelBase): Observable<any> {
    obj.UpdatedBy = localStorage.getItem("user");
    return this.http.put(this.url + "?id=" + id, obj);
  }

  delete(id: number, deletedBy?) {
    if (deletedBy) {
      return this.http.delete(
        this.url + "?id=" + id + "&deletedBy=" + deletedBy
      );
    }
    return this.http.delete(this.url + "?id=" + id);
  }

  encryptObject(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SecurityKeys.secret);
  }

  decryptObject(encryptedData) {
    var bytes = CryptoJS.AES.decrypt(
      encryptedData.toString(),
      SecurityKeys.secret
    );
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

  encryptString(myString) {
    return CryptoJS.AES.encrypt(myString, SecurityKeys.secret);
  }

  decryptString(myEncryptedString) {
    var bytes = CryptoJS.AES.decrypt(
      myEncryptedString.toString(),
      SecurityKeys.secret
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  getTimeZoneGMT() {
    let offset = new Date().getTimezoneOffset()
    let timeZone = ((offset < 0 ? 'GMT+' : 'GMT-') + // Note the reversed sign!
      this.pad((Math.abs(offset / 60)), 2) + ':' +
      this.pad(Math.abs(offset % 60), 2));
    return timeZone;

  }

  pad(number, length) {
    var str = "" + number
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

}
