import { Component, OnInit, ViewChild } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { CompanyService } from 'src/app/services/company.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent extends BasicFormComponent<any> implements OnInit {
  
  constructor(public companyService:CompanyService,public alertify:AlertifyService,private activatedRoute:ActivatedRoute) 
  {
    super(companyService,alertify)
   }
   
   companyTypes=[
    {
      companyType:'DOC'
    },
    {
      companyType:'Feed Company'
    },
    {
      companyType:'Medicine'
    },
    {
      companyType:'Parent Stock'
    },
    {
      companyType:'Machinery & Equipments'
    },
    {
      companyType:'Feed Ingredients'
    },
  ]

  btnName='Update';
  ngOnInit() {
    let id=this.activatedRoute.snapshot.params.id
    id?this.get(id):this.btnName='Add New'
   }
}
