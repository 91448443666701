import { Component, OnInit } from "@angular/core";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { MedicineTypeService } from "src/app/services/medicine-type.service";
import { AlertifyService } from "src/app/common/alertify.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-medicine-type-list",
  templateUrl: "./medicine-type-list.component.html",
  styleUrls: ["./medicine-type-list.component.scss"]
})
export class MedicineTypeListComponent extends BasicFormComponent<any>
  implements OnInit {
  models;
  constructor(
    public medicineTypeService: MedicineTypeService,
    public alertify: AlertifyService,
    private activatedRoute: ActivatedRoute
  ) {
    super(medicineTypeService, alertify);
  }
  headElements = ["Id", "Name", "SearchName","Composition" ,"ActiveStatus"];
  ngOnInit() {
   this.search();
  }
}
