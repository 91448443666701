import { BaseRequestModel } from './base-request-model';

export class ActiveFarmRequestModel extends BaseRequestModel {

     constructor(){
     super();
     this.OrderBy="FarmId"
   
     }
 
}
