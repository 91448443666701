import { Component, OnInit, EventEmitter } from "@angular/core";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { UserService } from "src/app/services/user.service";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { StateService } from "src/app/services/state.service";
import { CityService } from "src/app/services/city.service";
import { CountryService } from "src/app/services/country.service";
import { Country } from "src/app/models/country";
import { City } from "src/app/models/city";
import { State } from "src/app/models/state";
import { AlertifyService } from "src/app/common/alertify.service";
import { CompanyService } from "src/app/services/company.service";
import { InstituteService } from "src/app/services/institute.service";
import { FileUploader } from "ng2-file-upload";
import { environment } from 'src/environments/environment';
import { error } from 'protractor';

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"]
})
export class UserFormComponent extends BasicFormComponent<any>
  implements OnInit {
  btnName;
  userTypes = [
    {
      Id: 1,
      Title: "Employee"
    },
    {
      Id: 2,
      Title: "Owner"
    },
    {
      Id: 3,
      Title: "Client"
    },
    {
      Id: 4,
      Title: "Vendor"
    },
    {
      Id: 5,
      Title: "System"
    },
    {
      Id: 6,
      Title: "Doctor"
    }
  ];
  countries = [];
  cities = [];
  states = [];
  selectedCountry = new Country();
  selectedCity = new City();
  selectedState = new State();
  imagePath;
  profilePic;
  companies;
  institutes;
  listResponse;
  constructor(
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private cityService: CityService,
    private CountryService: CountryService,
    public alertify: AlertifyService,
    private companyService: CompanyService,
    private instituteService: InstituteService
  ) {
    super(userService, alertify);
  }
  getCountries() {
    this.CountryService.getCountries().subscribe(res => {
      this.countries = res;
    });
  }
  ngOnInit() {
    if (this.activatedRoute.snapshot.params.id) {
      this.btnName = "Update";
      this.userService
        .get(this.activatedRoute.snapshot.params.id)
        .subscribe(res => {
          this.object = res.Data;
          this.object.Password = "";
          console.log(this.object);
          this.getCountries();
          this.onSelectCountry(this.object.CountryId);
          this.onSelectState(this.object.StateId);
          this.imagePath =
            environment.baseUrl + '/images/NID/' +
            this.object.NIDCopy;
          this.profilePic =
            environment.baseUrl + '/images/ProfilePic/' +
            this.object.NIDCopy;
        });
    } else {
      this.btnName = "Add New";
      this.getCountries();
    }

    this.getCompanies();
    this.getInstitutes();
    if (history.state.data) {
      this.listResponse = history.state.data
      console.log(this.listResponse)
    }

  }


  onNidFileChanged(event) {
    const file = event.target.files[0];
    var myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      let imageCode: string = myReader.result.toString().split(',')[1];
      this.userService.uploadNid(this.object.Id, imageCode).subscribe(res => {
        this.ngOnInit()
        console.log(res)
        this.alertify.success(res.Message);

      }, error => this.alertify.error(error))
      console.log(imageCode);
    }

  }

  onProfilePicFileChanged(event) {
    const file = event.target.files[0];
    var myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      let imageCode: string = myReader.result.toString().split(',')[1];
      this.userService.uploadProfilePic(this.object.Id, imageCode).subscribe(res => {
        this.ngOnInit()
        console.log(res)
        this.alertify.success(res.Message);

      }, error => this.alertify.error(error))
      console.log(imageCode);
    }

  }
  onSelectCountry(countryId) {
    this.stateService.getStates(countryId).subscribe(res => {
      this.states = res;
    });
  }

  onSelectState(stateId) {
    this.cityService.getCities(stateId).subscribe(res => {
      this.cities = res;
    });
  }

  getCompanies() {
    this.companyService.getAll().subscribe(res => {
      this.companies = res;
    });
  }

  getInstitutes() {
    this.instituteService.getAll().subscribe(res => {
      this.institutes = res;
    });
  }


}
