import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FarmListComponent } from "./components/farms/farm-list/farm-list.component";

import { LoginPageComponent } from "./components/login-page/login-page.component";

import { DashboardLayoutComponent } from "./layouts/dashboard-layout/dashboard-layout.component";
import { AuthGuard } from "./auth/auth.guard";
import { LoginLayoutComponent } from "./layouts/login-layout/login-layout.component";
import { UserListComponent } from "./components/users/user-list/user-list.component";
import { FarmFormComponent } from "./components/farms/farm-form/farm-form.component";
import { UserFormComponent } from "./components/users/user-form/user-form.component";
import { BaseService } from "./common/base.service";
import { FarmService } from "./services/farm.service";
import { MedicineTypeListComponent } from "./components/medicine-types/medicine-type-list/medicine-type-list.component";
import { MedicineTypeFormComponent } from "./components/medicine-types/medicine-type-form/medicine-type-form.component";
import { CompanyListComponent } from "./components/companies/company-list/company-list.component";
import { CompanyFormComponent } from "./components/companies/company-form/company-form.component";
import { GroupListComponent } from "./components/groups/group-list/group-list.component";
import { GroupFormComponent } from "./components/groups/group-form/group-form.component";
import { MedicineListComponent } from "./components/medicines/medicine-list/medicine-list.component";
import { MedicineFormComponent } from "./components/medicines/medicine-form/medicine-form.component";
import { UserService } from './services/user.service';
import { MedicineTypeService } from './services/medicine-type.service';
import { CompanyService } from './services/company.service';
import { GroupService } from './services/group.service';
import { MedicineService } from './services/medicine.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TodaysFarmActivityComponent } from './components/dashboard/todays-farm-activity/todays-farm-activity.component';
import { ActivityListComponent } from './components/dashboard/activity-list/activity-list.component';
import { FarmDetailComponent } from './components/farms/farm-detail/farm-detail.component';
import { QueryComponent } from './components/db-query/query/query.component';
import { ActiveFarmComponent } from './components/dashboard/active-farm/active-farm.component';
import { CityService } from './services/city.service';
import { CityListComponent } from './components/cities/city-list/city-list.component';
import { CityFormComponent } from './components/cities/city-form/city-form.component';
import { DeviceListComponent } from "./components/devices/device-list/device-list.component";

const routes: Routes = [
  {
    path: "",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: "dashboard",
        component: DashboardComponent,
        resolve: { users: UserService }
      },
      
      {
        path: "device-list",
        component: DeviceListComponent
      },

      //FARM
      {
        path: "farm-list",
        component: FarmListComponent,
        resolve: { farms: FarmService }
      },
      {
        path: "add-farm",
        component: FarmFormComponent
      },
      {
        path: "farm-detail/:id",
        component: FarmDetailComponent
      },
      {
        path: "edit-farm/:id",
        component: FarmFormComponent
      },
      //USER
      {
        path: "user-list",
        component: UserListComponent,
        resolve: { users: UserService }
      },

      {
        path: "add-user",
        component: UserFormComponent
      },

      {
        path: "edit-user/:id",
        component: UserFormComponent
      },
      //MEDICINE TYPE
      {
        path: "medicine-type-list",
        component: MedicineTypeListComponent,
        resolve: { medicineTypes: MedicineTypeService }
      },
      {
        path: "add-medicine-type",
        component: MedicineTypeFormComponent
      },
      {
        path: "edit-medicine-type/:id",
        component: MedicineTypeFormComponent
      },
      //COMPANY
      {
        path: "company-list",
        component: CompanyListComponent,
        resolve: { companies: CompanyService }
      },
      {
        path: "add-company",
        component: CompanyFormComponent
      },
      {
        path: "edit-company/:id",
        component: CompanyFormComponent
      },

        //City
        {
          path: "city-list",
          component: CityListComponent,
          resolve: { cities: CityService }
        },
        {
          path: "add-city",
          component: CityFormComponent
        },
        {
          path: "edit-city/:id",
          component: CityFormComponent
        },
      //GROUP
      {
        path: "group-list",
        component: GroupListComponent,
        resolve: { groups: GroupService }
      },
      {
        path: "add-group",
        component: GroupFormComponent
      },
      {
        path: "edit-group/:id",
        component: GroupFormComponent
      },
      //MEDICINE
      {
        path: "medicine-list",
        component: MedicineListComponent,
        resolve: { medicines: MedicineService }
      },
      {
        path: "add-medicine",
        component: MedicineFormComponent
      },
      {
        path: "edit-medicine/:id",
        component: MedicineFormComponent
      },
      {
        path: "activity/:farmId",
        component: TodaysFarmActivityComponent
      },
      {
        path: "activity-list",
        component: ActivityListComponent
      },
      {
        path: "active-farm",
        component: ActiveFarmComponent
      },
      {
        path: "query",
        component: QueryComponent
      }
    ]
  },

  {
    path: "",
    component: LoginLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginPageComponent
      }
    ]
  },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
