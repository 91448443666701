import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { GroupService } from 'src/app/services/group.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent extends BasicFormComponent<any> implements OnInit {

  constructor(public groupService:GroupService,
    public alertify:AlertifyService,private activatedRoute:ActivatedRoute) {
    super(groupService,alertify)
   }

  btnName='Update';
  ngOnInit() {
    let id=this.activatedRoute.snapshot.params.id
    id?this.get(id):this.btnName='Add New'
   }
}
