import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'src/app/common/alertify.service';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss']
})
export class CityListComponent extends BasicFormComponent<any> implements OnInit {

  constructor(public cityService:CityService,public alertifyService:AlertifyService) {
    super(cityService,alertifyService)
   }

  headElements = ["Id", "Name", "StateName"];


  ngOnInit(){
   this.search();
   console.log(this.collection.data)
  }
}
