import { Injectable } from '@angular/core';
import { City } from '../models/city';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../common/base.service';
import { AlertifyService } from '../common/alertify.service';
import { UrlService } from '../common/url.service';

@Injectable({
  providedIn: 'root'
})
export class CityService extends BaseService<any> {

  constructor(public http:HttpClient,public urlService:UrlService) {
    super(http,urlService.city)
   }

  getCities(stateId):Observable<City[]>{
   return this.http.get<City[]>(environment.baseApiUrl+'Cities/LocaleListByCodeAndStateId?languageCode=en&stateId='+stateId)
  }

  saveLocalisedProperty(model){
    console.log(model)
   return  this.http.post(environment.baseApiUrl+'LocalizedProperties/LocalizedProperties',model);
  }
}
