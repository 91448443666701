import { Injectable } from '@angular/core';
import { BaseService } from '../common/base.service';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../common/url.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TodaysActivityService extends BaseService<any> {

  constructor(public http:HttpClient,url:UrlService) {
    super(http,url.todaysActivity)
   }

   getActiveFarms():Observable<ActiveFarm[]>{
    return this.http.get<ActiveFarm[]>(environment.baseApiUrl+'ActiveFarms');
   }
   getActiveUsers():Observable<ActiveUser[]>{
    return this.http.get<ActiveUser[]>(environment.baseApiUrl+'ActiveUsers');
   }
}

export class ActiveFarm{
  FarmId:number
  FarmName:string
}
export class ActiveUser{
  UserId:number
  UserName:string
}

