import { BaseRequestModel } from './base-request-model'

export class FarmRequestModel extends BaseRequestModel {
    constructor(){
        super();
        this.TimeZone=this.getTimeZoneGMT()
        this.ShowDeletedItem=false
    }
 
 TimeZone:string
 ShowDeletedItem:boolean
 Name:string
 OwnerName:string
 CountryId?:number
 StateId?:number
 CityId?:number
 From?:Date
 To?:Date


 getTimeZoneGMT(){
    let offset = new Date().getTimezoneOffset()
    let timeZone = ((offset<0? 'GMT+':'GMT-')+ // Note the reversed sign!
              this.pad((Math.abs(offset/60)), 2)+':'+
              this.pad(Math.abs(offset%60), 2));
      return timeZone;
    
   }
  
    pad(number, length){
      var str = "" + number
      while (str.length < length) {
          str = '0'+str
      }
      return str
  }
}
