import { Component, OnInit } from "@angular/core";
import { FarmService } from "src/app/services/farm.service";
import { StateService } from "src/app/services/state.service";
import { CountryService } from "src/app/services/country.service";
import { CityService } from "src/app/services/city.service";
import { Country } from "src/app/models/country";
import { State } from "src/app/models/state";
import { City } from "src/app/models/city";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { AlertifyService } from "src/app/common/alertify.service";
import { SecurityKeys } from 'src/app/common/SecurityKeys';
import { FarmRequestModel } from 'src/app/models/farm-request-model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

//| filterByFarm:filterByFarm |filterByOwner:filterByOwner | filterByPhone:filterByPhone"
@Component({
  selector: "app-farm-list",
  templateUrl: "./farm-list.component.html",
  providers: [StateService, CountryService, CityService]
})
export class FarmListComponent extends BasicFormComponent<any>
  implements OnInit {
  searchText;
  searchByFarm;
  searchByOwner;
  farms;
  countries = [];
  cities = [];
  states = [];
  selectedCountry = new Country();
  selectedCity = new City();
  selectedState = new State();
  requestModel;
  constructor(
    public farmService: FarmService,
    public alertify: AlertifyService,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private cityService: CityService,
    private CountryService: CountryService,
    private datePipe: DatePipe
  ) {
    super(farmService, alertify);
    this.requestModel = new FarmRequestModel();
  }
  headElements = [
    "Id",
    "Name",
    "Phone",
    "BirdTypeTitle",
    "OwnerName",
    "CountryName",
    "StateName",
    "CityName",
    "CreatedAt"
  ];

  ngOnInit() {
    this.search();
    this.getCountries();
  }

  getCountries() {
    this.CountryService.getCountries().subscribe(res => {
      this.countries = res;
    });
  }

  onSelectCountry(countryId) {
    this.stateService.getStates(countryId).subscribe(res => {
      this.states = res;
      this.search();
    });
  }

  onSelectState(stateId) {
    this.cityService.getCities(stateId).subscribe(res => {
      this.cities = res;
      this.search();
    });
  }

  onSelectCity() {
    this.search();
  }

  deleteFarm(id) {
    this.delete(id, Number.parseInt(localStorage.getItem('user')));
  }

  selectFromDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.requestModel.From = new Date(this.datePipe.transform(event.value, "yyyy-MM-dd"));

  }
  selectToDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date: Date = event.value;
    // date.setDate(date.getDate() + 1)
    this.requestModel.To = date
  }


  searching() {
    if (this.requestModel.To) {
      this.requestModel.To.setDate(this.requestModel.To.getDate() + 1)  //adding one day to javascript date
      this.requestModel.To = new Date(this.datePipe.transform(this.requestModel.To, "yyyy-MM-dd"))
    }
    this.baseService.getAllWithPagination(this.requestModel).subscribe(res => {
      this.collection.data = res.data;
      this.collection.count = res.count;
      this.config = {
        itemsPerPage: this.requestModel.PerPageCount,
        currentPage: this.requestModel.CurrentPage,
        totalItems: this.collection.count
      };
    });
  }

}
