import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/common/alertify.service';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { CityService } from 'src/app/services/city.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.scss']
})
export class CityFormComponent extends BasicFormComponent<any> implements OnInit {
  localizedProperty = {
    CityName:'',
    EntityId : '',
    LanguageId: 2,
    LocalKeyGroup: '',
    LocalKey: '',
    LocalValue: ''

   }
  constructor(public cityService: CityService,
    public alertifyService: AlertifyService,
    public activatedRoute: ActivatedRoute)
     {

    super(cityService, alertifyService);

     }
   btnName='Update';
  ngOnInit() {
  let id=this.activatedRoute.snapshot.params.id

  this.cityService.get(id).subscribe(res=>{
    this.localizedProperty.CityName=res.Name
    this.localizedProperty.EntityId=res.Id
    this.localizedProperty.LocalKey="Name"
    this.localizedProperty.LocalKeyGroup="City"


  })

  }
 // console.log(this.localizedProperty)
  saveLocal(){
    console.log(this.localizedProperty)
    this.cityService.saveLocalisedProperty(this.localizedProperty).subscribe(res=>{
      console.log(this.localizedProperty)
        console.log(res)
    })
  }

}
