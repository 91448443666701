import { Component, OnInit } from "@angular/core";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { FarmService } from "src/app/services/farm.service";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertifyService } from 'src/app/common/alertify.service';
import { CountryService } from 'src/app/services/country.service';
import { StateService } from 'src/app/services/state.service';
import { CityService } from 'src/app/services/city.service';
import { UserService } from 'src/app/services/user.service';
import { BirdTypeService } from 'src/app/services/bird-type.service';
import { Country } from 'src/app/models/country';
import { City } from 'src/app/models/city';
import { State } from 'src/app/models/state';

@Component({
  selector: "app-farm-form",
  templateUrl: "./farm-form.component.html",
  styleUrls: ["./farm-form.component.scss"]
})
export class FarmFormComponent extends BasicFormComponent<any>
  implements OnInit {
  constructor(
    public farmService: FarmService,
    private activatedRoute: ActivatedRoute,
    public alertify:AlertifyService,
    public countryService:CountryService,
    private stateService:StateService,
    private cityService:CityService,
    private userService:UserService,
    public birdTypeService:BirdTypeService
  ) {
    super(farmService,alertify);
  }
  countries=[];
  cities = [];
  states = [];
  birdTypes;
  owners;
  selectedCountry = new Country();
  selectedCity = new City();
  selectedState = new State();
  btnName='Update';
  ngOnInit() {
    let id=this.activatedRoute.snapshot.params.id
    id?this.farmService.get(id).subscribe(res=>{
        this.object = res.Data;
     
        this.onSelectCountry(this.object.CountryId);
        this.onSelectState(this.object.StateId);
    }):this.btnName='Add New'
    this.getCountries();
    this.getOwners();
    this.getBirdTypes();
     
   }

   getCountries(){
     this.countryService.getCountries().subscribe(res=>{
       this.countries=res;
     })
   }
   getBirdTypes(){
     this.birdTypeService.getBirdTypes().subscribe(res=>{
       this.birdTypes=res;
       console.log(this.birdTypes);
     })
   }
   getOwners(){
     this.userService.getOwners().subscribe(res=>{
       this.owners=res;
     })
   }

   onSelectCountry(countryId) {
    this.stateService.getStates(countryId).subscribe(res => {
      this.states = res;
    });
  }

  onSelectState(stateId) {
    this.cityService.getCities(stateId).subscribe(res => {
      this.cities = res;
    });
  }

  }
