import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient) { }

  getFarmsReport():Observable<FarmsReport>{
       return  this.http.get<FarmsReport>(environment.baseApiUrl+'Dashboard/farms')
  }
  getUsersReport():Observable<UsersReport>{
     return this.http.get<UsersReport>(environment.baseApiUrl+'Dashboard/users')
  }

  getTodaysFarmsAndUsers():Observable<TadaysActive>{
    return this.http.get<TadaysActive>(environment.baseApiUrl+'Dashboard/todaysActive')
  }

  getAllFarmsHistory():Observable<TotalHistory>{
    return  this.http.get<TotalHistory>(environment.baseApiUrl+'Dashboard/totalHistory')
  }

  getDashboardSummary():Observable<DashboardSummary>{
    return  this.http.get<DashboardSummary>(environment.baseApiUrl+'Dashboard/summary')
    }

}

export interface TotalHistory{
  totalEggs:number
  totalChicks:number
  totalParents:number
  totalBatches:number
}

export interface TadaysActive{
  usersToday:number
  farmsToday:number
}

export interface FarmsReport{
  total:number
  totalFarmsToday:number
  totalFarmsLastSevenDays:number
  totalFarmsLastFifteenDays:number
  totalFarmsCurrentMonth:number
  totalFarmsCurrentYear:number
}

export interface UsersReport{
  total:number
  totalUsersToday:number
  totalUsersLastSevenDays:number
  totalUsersLastFifteenDays:number
  totalUsersCurrentMonth:number
  totalUsersCurrentYear:number
}

export interface DashboardSummary{
  totalUsers:number
  totalFarms:number
  totalEggs:number
  totalChicks:number
  totalParents:number
  totalBatches:number
}

