export class BaseRequestModel {
    constructor(){
        this.CurrentPage=1
        this.PerPageCount=15
        this.OrderBy="Id"
       
    }
 CurrentPage:number
 PerPageCount:number
 OrderBy:string
 Keyword:string
 
}
