import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { State } from '../models/state';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private http:HttpClient) { }

  getStates(countryId):Observable<State[]>{
   return this.http.get<State[]>(environment.baseApiUrl+'States/LocaleListByCodeAndCountryId?countryId='+countryId)
  }

}