import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { MedicineService } from 'src/app/services/medicine.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-medicine-list',
  templateUrl: './medicine-list.component.html',
  styleUrls: ['./medicine-list.component.scss']
})
export class MedicineListComponent extends BasicFormComponent<any> implements OnInit {

  constructor(public medicineService:MedicineService,public alertify:AlertifyService,public activatedRoute:ActivatedRoute) {
    super(medicineService,alertify)
   }

   headElements = ["Id", "Name","MedicineForm", "MedicinePackSize","MRP","Dose","ActiveStatus","IsApproved"];

   medicines;
   ngOnInit() {
    this.search();
       
   }

}
