import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { AlertifyService } from 'src/app/common/alertify.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router, private alertify: AlertifyService) { }

  loginForm: FormGroup
  user: User
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      Phone: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(13)]],
      Password: ['', Validators.required],
    })
  }


  login() {
    this.user = Object.assign({}, this.loginForm.value)
    this.authService.login(this.user).subscribe(res => {
      console.log(res)
      if (res.Meta.Status == 0) {
        this.alertify.error(res.Meta.Message);
      }
      console.log("Status:" + res.Meta.Status)
      console.log("UserType:" + res.Meta.UserType)
      if (res.Meta.Status == 1) {
        console.log("Status:" + res.Meta.Status)
        if (res.Data.UserType == 5) {
          console.log("UserType:" + res.Meta.UserType)
          localStorage.setItem('UserId', res.Data.Phone)
          localStorage.setItem('LoggedInStatus', 'true')
          localStorage.setItem('user', res.Data.Id)
          this.router.navigate(['/'])
        } else {
          this.alertify.error("You have no permission");
        }

      }
    }, err => {
      this.alertify.error(err);
    })
  }

  public errorHandlingForReactiveForm = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  };



}



