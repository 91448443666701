import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { StateService } from "src/app/services/state.service";
import { CityService } from "src/app/services/city.service";
import { CountryService } from "src/app/services/country.service";
import { UserType } from "src/app/models/user-type";
import { BasicFormComponent } from "src/app/common/basic-form.component";
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { UserInfoRequestModel } from 'src/app/models/user-info-request-model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-user-list",
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends BasicFormComponent<any> implements OnInit {
  headElements = [
    "Id",
    "Name",
    "Phone",
    "UserTypeTitle",
    "CountryName",
    "StateName",
    "CityName",
    "CreatedAt"
  ];
  userTypes: UserType[] = [
    {
      Id: 1,
      Title: "Employee"
    },
    {
      Id: 2,
      Title: "Owner"
    },
    {
      Id: 3,
      Title: "Client"
    },
    {
      Id: 4,
      Title: "Vendor"
    },
    {
      Id: 5,
      Title: "System"
    },
    {
      Id: 6,
      Title: "Doctor"
    }
  ];

  countries = [];
  cities = [];
  states = [];
  requestModel;
  FromDate;
  ToDate;
  constructor(
    public userService: UserService,
    public alertify: AlertifyService,
    private stateService: StateService,
    private cityService: CityService,
    private CountryService: CountryService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    super(userService, alertify);
    this.requestModel = new UserInfoRequestModel();
  }

  ngOnInit() {

    if (history.state.data) {
      this.requestModel = history.state.data;
      this.searching();
      if (history.state.data.From) {
        this.requestModel.From =
          this.FromDate = new FormControl(history.state.data.From)
      }
      if (history.state.data.To) {
        this.ToDate = new FormControl(history.state.data.To)
        // new Date(this.datePipe.transform(history.state.data.To, "yyyy-MM-dd"))
      }
    } else {
      this.search();
    }

    this.getCountries();
    if (this.requestModel.CountryId) {
      this.onSelectCountry(this.requestModel.CountryId);
    }

    if (this.requestModel.StateId) {
      this.onSelectState(this.requestModel.StateId);
    }



  }


  getCountries() {
    this.CountryService.getCountries().subscribe(res => {
      this.countries = res;
    });
  }

  onSelectCountry(countryId) {
    this.stateService.getStates(countryId).subscribe(res => {
      this.states = res;
      this.search();
    });
  }

  onSelectState(stateId) {
    this.cityService.getCities(stateId).subscribe(res => {
      this.cities = res;
      this.search();
    });
  }

  onSelectCity() {
    this.search();
  }

  onSelectUserType() {
    this.search();
  }

  deleteUser(id) {
    this.delete(id, Number.parseInt(localStorage.getItem('user')));
  }

  selectFromDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.requestModel.From = new Date(this.datePipe.transform(event.value, "yyyy-MM-dd"));

  }
  selectToDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date: Date = event.value;
    // date.setDate(date.getDate() + 1)
    this.requestModel.To = date
  }


  searching() {
    if (this.requestModel.To) {
      this.requestModel.To.setDate(this.requestModel.To.getDate() + 1)  //adding one day to javascript date
      this.requestModel.To = new Date(this.datePipe.transform(this.requestModel.To, "yyyy-MM-dd"))
    }

    this.baseService.getAllWithPaginationWhenPageChanged(this.requestModel).subscribe(res => {
      this.collection.data = res.data;
      this.collection.count = res.count;
      this.config = {
        itemsPerPage: this.requestModel.PerPageCount,
        currentPage: this.requestModel.CurrentPage,
        totalItems: this.collection.count
      };
    });
  }
}
