import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { GroupService } from 'src/app/services/group.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent extends BasicFormComponent<any> implements OnInit {

  constructor(public groupService:GroupService,public alertify:AlertifyService,public activatedRoute:ActivatedRoute) {
    super(groupService,alertify)
   }
   headElements = ["Id", "Trade Name", "Group Name", "Active Status","Is Approved"];
  ngOnInit() {
   this.search();
  }

}
