import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { TodaysActivityService } from 'src/app/services/todays-activity.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivityRequestModel } from 'src/app/models/activity-request-model';
import { ActiveFarmService } from 'src/app/services/active-farm.service';
import { ActiveFarmRequestModel } from 'src/app/models/active-farm-request-model';

@Component({
  selector: 'app-active-farm',
  templateUrl: './active-farm.component.html',
  styleUrls: ['./active-farm.component.scss']
})
export class ActiveFarmComponent extends BasicFormComponent<any> implements OnInit {
  constructor(public activeFarmService:ActiveFarmService,public alertify:AlertifyService ){
    super(activeFarmService,alertify)
    this.requestModel=new ActiveFarmRequestModel();
  }
  
  ngOnInit(): void {
    this.search();
    }
        
    displayedColumns = ['FarmId','FarmName','Phone','Area','BirdType','action'];
    searching(){
    this.search();
    } 
  }