import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FileUploadModule } from 'ng2-file-upload';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FarmListComponent } from './components/farms/farm-list/farm-list.component';

import { LoginPageComponent } from './components/login-page/login-page.component';

import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { TokenInterceptorService } from './auth/token-interceptor.service';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { FarmService } from './services/farm.service';
import { UserService } from './services/user.service';
import { AngularmaterialModule } from './material/angularmaterial/angularmaterial.module';
import { BaseService } from './common/base.service';
import { FilterByFarmPipe } from './pipes/filter-by-farm.pipe';
import {NgxPaginationModule} from 'ngx-pagination';

import { FilterByOwnerPipe } from './pipes/filter-by-owner.pipe';
import { FilterByPhonePipe } from './pipes/filter-by-phone.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { FarmFormComponent } from './components/farms/farm-form/farm-form.component';
import { UserFormComponent } from './components/users/user-form/user-form.component';
import { AlertifyService } from './common/alertify.service';
import { MedicineTypeListComponent } from './components/medicine-types/medicine-type-list/medicine-type-list.component';
import { MedicineTypeFormComponent } from './components/medicine-types/medicine-type-form/medicine-type-form.component';
import { CompanyFormComponent } from './components/companies/company-form/company-form.component';
import { CompanyListComponent } from './components/companies/company-list/company-list.component';
import { GroupListComponent } from './components/groups/group-list/group-list.component';
import { GroupFormComponent } from './components/groups/group-form/group-form.component';
import { MedicineListComponent } from './components/medicines/medicine-list/medicine-list.component';
import { MedicineFormComponent } from './components/medicines/medicine-form/medicine-form.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TodaysFarmActivityComponent } from './components/dashboard/todays-farm-activity/todays-farm-activity.component';
import { ActivityListComponent } from './components/dashboard/activity-list/activity-list.component';
import { DatePipe } from '@angular/common';
import { FarmDetailComponent } from './components/farms/farm-detail/farm-detail.component';
import { QueryComponent } from './components/db-query/query/query.component';
import { ActiveFarmComponent } from './components/dashboard/active-farm/active-farm.component';
import { ActivityComponent } from './components/dashboard/activity/activity.component';
import { CityFormComponent } from './components/cities/city-form/city-form.component';
import { CityListComponent } from './components/cities/city-list/city-list.component';
import { LinkDirective } from './Directive/link.directive';
import { DeviceListComponent } from './components/devices/device-list/device-list.component';
import { DeviceService } from './services/device.service';




@NgModule({
  declarations: [
    AppComponent,
    FarmListComponent,
    LoginPageComponent,
    LoginLayoutComponent,
    DashboardLayoutComponent,
    DeviceListComponent,
    UserListComponent,
    FilterByFarmPipe,
    FilterByOwnerPipe,
    FilterByPhonePipe,
    SortByPipe,
    LoaderComponent,
    FarmFormComponent,
    UserFormComponent,
    MedicineTypeListComponent,
    MedicineTypeFormComponent,
    CompanyListComponent,
    CompanyFormComponent,
    GroupListComponent,
    GroupFormComponent,
    MedicineListComponent,
    MedicineFormComponent,
    DashboardComponent,
    TodaysFarmActivityComponent,
    ActivityListComponent,
    FarmDetailComponent,
    QueryComponent,
    ActiveFarmComponent,
    ActivityComponent,
    CityFormComponent,
    CityListComponent,
    LinkDirective,
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    AngularmaterialModule,
    Ng2SearchPipeModule,
    MDBBootstrapModule.forRoot(),
    FileUploadModule,
    NgxPaginationModule
  ],
  providers: [
    AuthService, AuthGuard,
    FarmService,
    UserService,
    DeviceService,
    AlertifyService,
    DatePipe,
  
  
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptorService,
      multi:true
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
