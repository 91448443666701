import { BaseRequestModel } from './base-request-model';

export class ActivityRequestModel extends BaseRequestModel {
    FarmId: number
    UserId?: number
    BatchName: string
    UserName: string
    public From?: Date
    public To?: Date
    constructor() {
        super();
        this.OrderBy = "Time"

    }


}
