import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, NgForm } from "@angular/forms";
import { ModelBase } from "./model-base";
import { Router, ActivatedRoute } from "@angular/router";
import { BaseService } from "./base.service";
import { NgbPaginationNumber } from "@ng-bootstrap/ng-bootstrap";
import { AlertifyService } from "./alertify.service";
import { BaseRequestModel } from '../models/base-request-model';

export class BasicFormComponent<T extends ModelBase> {
  object: T;
  objects: any;
  formGroup: FormGroup;
  ngForm: NgForm;
  constructor(
    public baseService: BaseService<T>,
    public alertify: AlertifyService
  ) {
    this.object = this.createInstance(ModelBase) as T;
  }

  createInstance<ModelBase>(c: new () => ModelBase): ModelBase {
    return new c();
  }

  save() {
    this.baseService.save(this.object).subscribe(
      res => {
        this.alertify.success("Added Successfully !");
        this.object = this.createInstance(ModelBase) as T; //for resetting the input fields
      },
      error => {
        console.log(error);
        this.alertify.error("Something went wrong");
      }
    );
  }

  edit(id: number) {
    this.baseService.edit(id, this.object).subscribe(
      res => {
        this.alertify.success("Updated Successfully !");
      },
      error => {
        console.log(error);
        this.alertify.error("Something went wrong");
      }
    );
  }

  delete(id: number, deletedBy?: number) {
    var result = confirm("Are You sure to delete this");
    if (result) {
      this.baseService.delete(id, deletedBy).subscribe(
        res => {
          this.alertify.success("Deleted Successfully !");
          this.search() //for refreshing
        },
        error => {
          console.log(error);
          this.alertify.error("Something went wrong");
        }
      );
    }
  }

  get(id: number) {
    this.baseService.get(id).subscribe(
      res => {
        this.object = res;
        console.log(this.object);
      },
      error => {
        console.log(error);
        this.alertify.error("Something went wrong");
      }
    );
  }

  getAll(showDeletedItem?: boolean) {
    this.baseService.getAll(showDeletedItem).subscribe(
      res => {
        console.log("success");
        this.objects = res;
      },
      error => {
        console.log(error);
        this.alertify.error("Something went wrong");
      }
    );
  }
  
/* .........search and pagination start..............*/
public autoHide: boolean = true;
public labels: any = {
  previousLabel: "",
  nextLabel: ""
};
config: any;
collection = { count: 0, data: [] };
requestModel=new BaseRequestModel();

  pageChanged(currentPage) {
    this.searchWhenPageChanged(currentPage);
  }
  search() {
    
    this.baseService.getAllWithPagination(this.requestModel).subscribe(res => { 
      this.collection.data = res.data;
      console.log(this.collection.data);
      this.collection.count = res.count;
      this.config = {
        itemsPerPage: this.requestModel.PerPageCount,
        currentPage: this.requestModel.CurrentPage,
        totalItems: this.collection.count
      };
    });
  }

  searchWhenPageChanged(currentPage) {
    this.requestModel.CurrentPage = currentPage;
    this.baseService.getAllWithPaginationWhenPageChanged(this.requestModel).subscribe(res => {
      this.collection.data = res.data;
      this.collection.count = res.count;
      this.config = {
        itemsPerPage: this.requestModel.PerPageCount,
        currentPage: this.requestModel.CurrentPage,
        totalItems: this.collection.count
      };
    });
  }
/* ........search and pagination end.............*/
  submitForm() {
    this.object.Id ? this.edit(Number.parseInt(this.object.Id)) : this.save();
  }

  public errorHandling = (
    control: string,
    error: string,
    templateForm: NgForm
  ) => {
    return templateForm.controls[control].hasError(error);
  };
  public errorHandlingForReactiveForm = (control: string, error: string) => {
    return this.formGroup.controls[control].hasError(error);
  };
}
