import { Injectable } from '@angular/core';
import { BaseService } from '../common/base.service';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../common/url.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService<any> {
  constructor(public http:HttpClient,public urlService:UrlService) { 
    super(http,urlService.company)
  }

 
}


