import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DashboardService, TotalHistory, FarmsReport, UsersReport, TadaysActive, DashboardSummary } from 'src/app/services/dashboard.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardService]
})
export class DashboardComponent implements OnInit {

  constructor(public dashboardService: DashboardService) { }
  
  dashboardSummary : DashboardSummary;

  ngOnInit() {
    let summary = this.dashboardService.getDashboardSummary();

    forkJoin([summary]).subscribe(results => {
      this.dashboardSummary = results[0];
    });


    this.dashboardService.getDashboardSummary().subscribe(summary => {
      this.dashboardSummary = summary;
    });

  }


}
