import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from 'src/app/common/basic-form.component';
import { CompanyService } from 'src/app/services/company.service';
import { AlertifyService } from 'src/app/common/alertify.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent extends BasicFormComponent<any> implements OnInit {

  constructor(public companyService: CompanyService, public alertify: AlertifyService, public activatedRoute: ActivatedRoute) {
    super(companyService, alertify)

  }
  companyTypes = [
    {
      companyType: 'DOC'
    },
    {
      companyType: 'Feed Company'
    },
    {
      companyType: 'Medicine'
    },
    {
      companyType: 'Parent Stock'
    },
    {
      companyType: 'Machinery & Equipments'
    },
    {
      companyType: 'Feed Ingredients'
    },
  ]
  headElements = ["Id", "Name", "SearchName", "CompanyType", "ActiveStatus", "IsApproved"];


  ngOnInit() {
    this.search();
  }

}

