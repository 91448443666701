import { Injectable } from '@angular/core';
import { BaseService } from '../common/base.service';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../common/url.service';
import { Observable } from 'rxjs';
import { ActivityTracker } from '../models/activity-tracker';
import { environment } from 'src/environments/environment';
import { ActivityRequestModel } from '../models/activity-request-model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService<any> {

  constructor(public http: HttpClient, url: UrlService) {
    super(http, url.activity)
  }

  getTodaysActivity(model: ActivityRequestModel): Observable<ActivityTracker[]> {
    return this.http.post<ActivityTracker[]>(environment.baseApiUrl + 'TodaysActivities/Pagedlist', model)
  }

}

