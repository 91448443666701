import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  constructor(private http:HttpClient) { }

  getDeviceInfos():Observable<DeviceInfo[]>{
       return  this.http.get<DeviceInfo[]>(environment.baseApiUrl+'Device/getinfo')
  }

}

export interface DeviceInfo{
  packet_type: string;
  humidity: number;
  gateway_id: string;
  created_at: string;
  id: number;
  temperature: number;
  ammonia_value: number;
}


