import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from '../models/country';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from '../common/base.service';
import { UrlService } from '../common/url.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService<any> {

  constructor(public http:HttpClient,url:UrlService) {
    super(http,url.country)
   }

  getCountries():Observable<Country[]>{
   return this.http.get<Country[]>(environment.baseApiUrl+'Countries/LocaleListByCode')
  }
}
